var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Button } from '@mui/material';
import { PaymentInfo } from './views/payment';
import { AccountInfo } from './views/account';
import { gql, useQuery } from '@apollo/client';
import { ContactUs } from './views/contact';
// import { API_URL } from 'edutech-frontend/src/App';
import { API_URL } from '../../App';
export var ProfileView = function () {
    var _a, _b, _c;
    var data = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query GetProfile {\n            me {\n                name\n                email\n\n                paymentMethods {\n                    id\n                    object\n\n                    last4\n\n                    exp_month\n                    exp_year\n                }\n\n            }\n        }\n    "], ["\n        query GetProfile {\n            me {\n                name\n                email\n\n                paymentMethods {\n                    id\n                    object\n\n                    last4\n\n                    exp_month\n                    exp_year\n                }\n\n            }\n        }\n    "])))).data;
    var logout = function () {
        fetch("".concat(API_URL, "/api/auth/logout"), { method: "POST", credentials: 'include' }).then(function () {
            // navigate('/')
            window.location.href = "/";
        });
    };
    console.log({ data: data });
    return (_jsxs(Box, __assign({ sx: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '10%',
            paddingRight: '10%',
        } }, { children: [_jsxs(Box, __assign({ sx: {
                    padding: '6px',
                    paddingTop: '12px',
                    flex: 1, display: 'flex', flexDirection: 'column',
                } }, { children: [_jsx(AccountInfo, { email: (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.email, name: (_b = data === null || data === void 0 ? void 0 : data.me) === null || _b === void 0 ? void 0 : _b.name }), _jsx(PaymentInfo, { methods: ((_c = data === null || data === void 0 ? void 0 : data.me) === null || _c === void 0 ? void 0 : _c.paymentMethods) || [] }), _jsx(ContactUs, {})] })), _jsx(Button, __assign({ onClick: logout, color: "secondary", variant: "contained", sx: { marginTop: '12px' } }, { children: "Logout" }))] })));
};
var templateObject_1;
