var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Paper } from '@mui/material';
import { API_URL } from '../../App';
import { CourseImage } from '../../components/course-image';
import React, { useMemo } from 'react';
import { LearningZone } from './learning-zone';
import { CertificateOutcomes, CertificateSummary, MultichoiceAssessment } from './parts';
export * from './preview';
var BaseCourse = function (props) {
    // const [ activeStage ] = useState(0)
    var _a, _b, _c, _d, _e;
    // console.log({course: props.course})
    var imageSrc = useMemo(function () {
        var _a, _b;
        if ((_a = props.course) === null || _a === void 0 ? void 0 : _a.image) {
            return "".concat(API_URL, "/api/").concat((_b = props.course) === null || _b === void 0 ? void 0 : _b.id, "/image");
        }
        return undefined;
    }, [(_a = props.course) === null || _a === void 0 ? void 0 : _a.image]);
    return (_jsxs(Paper, __assign({ className: props.className, sx: {
            flex: 1,
            borderRadius: '12px',
            padding: '24px',
            display: 'flex',
            overflowY: 'auto',
            flexDirection: 'column'
        } }, { children: [_jsxs(Box, __assign({ className: "course-info__header", sx: { display: 'flex', justifyContent: 'space-between' } }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ className: "course-info__name", fontWeight: "bold", fontSize: "23px" }, { children: (_b = props.course) === null || _b === void 0 ? void 0 : _b.name })), _jsx(Typography, __assign({ className: "course-info__shortDescription" }, { children: (_c = props.course) === null || _c === void 0 ? void 0 : _c.shortDescription }))] }), _jsx(CourseImage, { src: imageSrc })] })), _jsxs(Box, __assign({ sx: { flex: 1, marginTop: '12px', display: 'flex', flexDirection: 'column' } }, { children: [_jsx(LearningZone
                    // activeStage={activeStage}
                    , { 
                        // activeStage={activeStage}
                        course: props.course }), _jsxs(Box, __assign({ sx: { display: 'flex', marginTop: '24px' } }, { children: [_jsxs(Box, __assign({ className: "course-info", sx: { flex: 0.6 } }, { children: [_jsx(CertificateSummary, { children: (_d = props.course) === null || _d === void 0 ? void 0 : _d.description }), _jsx(CertificateOutcomes, { outcomes: ((_e = props.course) === null || _e === void 0 ? void 0 : _e.outcomes) || [] }), _jsx(MultichoiceAssessment, {})] })), _jsx(Box, { className: "vert-spacer", sx: { flex: 0.4 } })] }))] }))] })));
};
export var Course = styled(BaseCourse)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media only screen and (max-width: 768px) {\n        .vert-spacer {\n            display: none;\n        }\n\n        .course-info {\n            flex: 1;\n        }\n\n        .course-info__header .course-image {\n            display: none;\n        }\n\n        .course-info__header .course-info__shortDescription, .course-info__header .course-info__name {\n            text-align: center;\n        }\n    }\n"], ["\n    @media only screen and (max-width: 768px) {\n        .vert-spacer {\n            display: none;\n        }\n\n        .course-info {\n            flex: 1;\n        }\n\n        .course-info__header .course-image {\n            display: none;\n        }\n\n        .course-info__header .course-info__shortDescription, .course-info__header .course-info__name {\n            text-align: center;\n        }\n    }\n"])));
var templateObject_1;
