var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cancel, CheckCircle } from '@mui/icons-material';
import { Chip, Paper, styled } from '@mui/material';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PurchaseCertificateModal } from '../home/components/purchase-certificate';
var BaseCourseComplete = function (props) {
    var _a, _b, _c, _d;
    var course = (props || {}).course;
    var navigate = useNavigate();
    // const stages = [
    //     {   
    //         name: 'Name',
    //         questions: [
    //             {answers: [],
    //             answer: {answer: '', isCorrect: true}}
    //         ]
    //     }
    // ]
    var stages = useMemo(function () {
        var _a, _b;
        return (((_b = (_a = course === null || course === void 0 ? void 0 : course.stages) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; })) || []).map(function (stage) {
            var _a, _b;
            return (__assign(__assign({}, stage), { questions: (_b = (_a = stage.questions) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; }) }));
        });
    }, course === null || course === void 0 ? void 0 : course.stages);
    var _e = useState(null), purchaseModalOpen = _e[0], openPurchaseModal = _e[1];
    var questions = (_c = (_b = (_a = course === null || course === void 0 ? void 0 : course.stages) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; })) === null || _c === void 0 ? void 0 : _c.reduce(function (prev, curr) { var _a, _b; return prev.concat(((_b = (_a = curr.questions) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; })) || []); }, []);
    var correct = questions === null || questions === void 0 ? void 0 : questions.reduce(function (prev, curr) { var _a; return prev + (((_a = curr === null || curr === void 0 ? void 0 : curr.answer) === null || _a === void 0 ? void 0 : _a.isCorrect) ? 1 : 0); }, 0);
    var total = questions === null || questions === void 0 ? void 0 : questions.length;
    return (_jsxs(Paper, __assign({ className: props.className, sx: { display: 'flex', borderRadius: '12px', padding: '12px', marginLeft: '8vw', marginRight: '8vw', flexDirection: 'column', flex: 1 } }, { children: [_jsx(PurchaseCertificateModal, { open: Boolean(purchaseModalOpen), course: { id: purchaseModalOpen }, onClose: function () {
                    openPurchaseModal(null);
                } }), _jsx(Typography, __assign({ fontSize: '20px' }, { children: correct === total ? "Congratulations on successfully answering all of the correct answers. You are now eligible to become certified for this certificate. Certificates can be purchased and will be available for immediate download." : "Well done on working your way through the course. In order to complete this certificate you need to answer all of the multi-choice questions correctly. Please go back and try again." })), _jsxs(Box, __assign({ sx: { flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column', marginTop: '24px' } }, { children: [_jsx(Typography, __assign({ sx: { marginBottom: '12px' } }, { children: "Answers" })), _jsx(Box, __assign({ sx: { flex: 1, minHeight: 0, overflowY: 'auto' } }, { children: (_d = stages === null || stages === void 0 ? void 0 : stages.filter(function (a) { return (a.questions || []).length > 0; })) === null || _d === void 0 ? void 0 : _d.map(function (stage, ix) {
                            var _a;
                            return (_jsxs(Box, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Chip, { label: ix + 1 }), _jsx(Typography, __assign({ sx: { marginLeft: '12px' } }, { children: stage.name }))] })), _jsx(Box, __assign({ sx: { paddingLeft: '36px', marginTop: '12px' } }, { children: (_a = stage.questions) === null || _a === void 0 ? void 0 : _a.map(function (question, yx) {
                                            var _a, _b, _c;
                                            return (_jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '12px', alignItems: 'center', justifyContent: 'space-between' } }, { children: [_jsx(Chip, { label: "".concat(ix + 1, ".").concat(yx + 1) }), _jsx(Typography, { children: (_b = (_a = question === null || question === void 0 ? void 0 : question.answers) === null || _a === void 0 ? void 0 : _a.find(function (a) { var _a; return a.id === ((_a = question === null || question === void 0 ? void 0 : question.answer) === null || _a === void 0 ? void 0 : _a.answer); })) === null || _b === void 0 ? void 0 : _b.text }), ((_c = question === null || question === void 0 ? void 0 : question.answer) === null || _c === void 0 ? void 0 : _c.isCorrect) ? _jsx(CheckCircle, { color: "success" }) : _jsx(Cancel, { color: "error" })] })));
                                        }) }))] }));
                        }) }))] })), _jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Box, __assign({ sx: { flex: 1 } }, { children: _jsx(Button, __assign({ onClick: function () { return navigate('../'); } }, { children: "Return to lessons" })) })), _jsx(Box, __assign({ sx: { flex: 1, alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex' } }, { children: correct === total && !(course === null || course === void 0 ? void 0 : course.certified) && _jsx(Button, __assign({ onClick: function () { var _a; return openPurchaseModal((_a = props.course) === null || _a === void 0 ? void 0 : _a.id); }, variant: "contained" }, { children: "Purchase Certificate" })) })), _jsx(Box, __assign({ sx: { flex: 1, display: 'flex', justifyContent: 'flex-end' } }, { children: _jsxs(Typography, __assign({ fontWeight: "bold", fontSize: '23px' }, { children: ["Total: ", correct, "/", total] })) }))] }))] })));
};
export var CourseComplete = styled(BaseCourseComplete)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media only screen and (max-width: 768px) {\n        margin-left: 0;\n        margin-right: 0;\n    }\n"], ["\n    @media only screen and (max-width: 768px) {\n        margin-left: 0;\n        margin-right: 0;\n    }\n"])));
var templateObject_1;
