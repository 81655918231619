var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
export var AccountInfo = function (props) {
    return (_jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: _jsxs(Paper, __assign({ sx: { display: 'flex', flexDirection: 'column', overflow: 'hidden' } }, { children: [_jsx(Box, __assign({ sx: { bgcolor: 'secondary.main', color: 'background.default', padding: '12px' } }, { children: _jsx(Typography, { children: "Account Details" }) })), _jsx(Divider, {}), _jsxs(Box, __assign({ sx: { padding: '24px', display: 'flex', flexDirection: 'column' } }, { children: [_jsx(TextField, { size: "small", label: "Name", value: props.name || '' }), _jsx(TextField, { size: "small", label: "E-mail", sx: { marginTop: '12px' }, value: props.email || '' }), _jsx(Divider, {}), _jsx(TextField, { sx: { marginTop: '12px' }, size: "small", label: "Password", type: "password" })] }))] })) })));
};
