var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Paper, TextField } from "@mui/material";
import Logo from '../../assets/logo-dark.svg';
import Background from './background.svg';
import { Link, useSearchParams } from 'react-router-dom';
import { API_URL } from '../../App';
import { Typography } from '@mui/material';
export var Signup = function (props) {
    var _a = useState(''), name = _a[0], setName = _a[1];
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    var _c = useState(''), password = _c[0], setPassword = _c[1];
    var _d = useState(''), confirmPassword = _d[0], setConfirmPassword = _d[1];
    var _e = useState(false), signupResult = _e[0], setSignupResult = _e[1];
    var searchParams = useSearchParams()[0];
    var token = searchParams.get('token');
    useEffect(function () {
        if (token) {
            fetch("".concat(API_URL, "/api/auth/signup"), {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: token
                })
            }).then(function (res) { return res.json(); }).then(function (data) {
                console.log("Signup data", data);
                if (data.success) {
                    window.location.href = "/";
                }
            });
        }
    }, [token]);
    var trySignup = function () {
        fetch("".concat(API_URL, "/api/auth/signup"), {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                password: password
            })
        }).then(function (res) { return res.json(); }).then(function (data) {
            console.log("Signup data", data);
            if (data.success) {
                //Set success 
                setSignupResult(true);
                //Timeout then redirect
                setTimeout(function () { return window.location.href = "/"; }, 5 * 1000);
            }
            else {
                //Set error info
                setSignupResult(data.error);
            }
            // window.location.href = "/";
        });
        // if(passwor)
        // props.onLogin?.({ email, password })
    };
    var signupValid = function () {
        if (password.length > 0 || confirmPassword.length > 0 || password !== confirmPassword)
            return false;
        return true;
    };
    return (_jsxs(Box, __assign({ sx: { position: 'relative', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' } }, { children: [_jsxs(Paper, __assign({ sx: { display: 'flex', minWidth: '30vw', padding: '12px', flexDirection: 'column' } }, { children: [_jsx(Box, __assign({ sx: { marginBottom: '24px', display: 'flex', justifyContent: 'center' } }, { children: _jsx("img", { alt: "ILL Logo", src: Logo, style: { height: '70px' } }) })), token ? (_jsxs(Box, __assign({ sx: { flex: 1, marginBottom: '12px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' } }, { children: [_jsx(Typography, __assign({ fontWeight: "bold", fontSize: "18px" }, { children: "Verifying account" })), _jsx(CircularProgress, { sx: { marginTop: '12px' } })] }))) : signupResult !== false && typeof (signupResult) !== 'string' ? (_jsxs(Box, { children: [_jsx(Typography, __assign({ fontSize: 'large' }, { children: "Signup successfull, check your email for the verification link." })), _jsx(Typography, __assign({ sx: { marginTop: '12px', marginBottom: '12px', textAlign: 'center' } }, { children: "Redirecting to login in 5 seconds ..." }))] })) : (_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [signupResult !== false && typeof (signupResult) === 'string' && _jsx(Typography, __assign({ color: "error" }, { children: signupResult })), _jsx(TextField, { value: name, onChange: function (e) { return setName(e.target.value); }, size: "small", sx: { marginTop: '6px', marginBottom: '6px' }, label: "Name" }), _jsx(TextField, { value: email, onChange: function (e) { return setEmail(e.target.value); }, sx: { marginTop: '6px', marginBottom: '6px' }, size: "small", label: "Email" }), _jsx(TextField, { value: password, onChange: function (e) { return setPassword(e.target.value); }, size: "small", onKeyDown: function (e) {
                                    // if (e.key === 'Enter') {
                                    // tryLogin();
                                    // }
                                }, sx: { marginTop: '6px', marginBottom: '6px' }, label: "Password", type: "password" }), _jsx(TextField, { value: confirmPassword, onChange: function (e) { return setConfirmPassword(e.target.value); }, size: "small", label: "Confirm Password", sx: { marginTop: '6px', marginBottom: '6px' }, type: "password", onKeyDown: function (e) {
                                    if (e.key === 'Enter' && signupValid()) {
                                        trySignup();
                                    }
                                } }), _jsx(Box, __assign({ sx: { marginBottom: '12px', marginTop: '12px' } }, { children: _jsxs(Button, __assign({ disabled: props.authenticating || signupValid(), onClick: function () {
                                        trySignup();
                                        // navigate('/dashboard')
                                    }, fullWidth: true, variant: "contained", color: "secondary" }, { children: [props.authenticating ? _jsx(CircularProgress, { size: "small" }) : "", " Signup"] })) }))] }))), _jsx(Divider, {}), _jsx(Box, __assign({ sx: { '& > a': { padding: '6px' }, '& > a:hover': { background: '#dfdfdf', padding: '6px', borderRadius: '12px', opacity: 0.8 }, color: 'black', marginTop: '6px', alignItems: 'center', display: 'flex', flexDirection: 'column' } }, { children: _jsx(Link, __assign({ style: { color: 'black', textDecoration: 'none', width: '100%', textAlign: 'center' }, to: "/" }, { children: "Return to Login" })) }))] })), _jsx(Box, { sx: { position: 'absolute', zIndex: -1, top: 0, left: 0, right: 0, bottom: 0, backgroundSize: '110px', backgroundImage: "url(".concat(Background, ")"), } })] })));
};
