var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Paper } from '@mui/material';
import React from 'react';
export var CourseImage = function (props) {
    return (_jsx(Paper, __assign({ className: "course-image", elevation: 3, sx: { display: 'flex', height: 100, width: 100 } }, { children: _jsx("img", { style: { width: 100, height: 100, borderRadius: '12px', overflow: 'hidden' }, alt: "", src: props.src }) })));
};
