var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import moment from 'moment';
export var StoreCard = function (props) {
    var _a;
    useEffect(function () {
        // fetch(`https://source.unsplash.com/300x300?id=${props.id}`, {
        //     cache: 'no-cache',
        //     // redirect: 'manual'
        // }).then((r) => {
        //     console.log(r)
        // })
    }, []);
    return (_jsxs(Paper, __assign({ className: "store-card", elevation: 5, onClick: props.onClick, sx: {
            // bgcolor: 'primary.main',
            borderRadius: '12px',
            width: '500px',
            minHeight: '150px',
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            margin: '12px',
            overflow: 'hidden',
            padding: '24px'
        } }, { children: [_jsx(Box, { sx: { height: 80, width: 80, alignSelf: 'center', marginLeft: '12px', marginRight: '12px', backgroundImage: "url(https://api.internetlearning.io/api/".concat(props.id, "/image)"), backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' } }), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1, padding: '6px' } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1, paddingBottom: '12px' } }, { children: [_jsx(Typography, __assign({ color: "gray", fontSize: "12px" }, { children: "Certificate" })), _jsx(Typography, __assign({ fontWeight: "bold", sx: { marginBottom: '12px' }, fontSize: "lg" }, { children: props.name })), _jsxs(Typography, __assign({ className: "course-description", fontSize: "15px" }, { children: [(_a = props.shortDescription) === null || _a === void 0 ? void 0 : _a.substring(0, 120), (props.shortDescription || '').length > 120 ? '...' : ''] }))] })), _jsxs(Typography, __assign({ className: "course-duration" }, { children: ["Duration: ", moment.duration(props.duration || 0, 'seconds').asMinutes(), " minutes"] }))] }))] })));
};
