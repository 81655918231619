var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Box } from '@mui/material';
export var VideoZone = function (props) {
    return (_jsx(Box, __assign({ sx: {
            borderRadius: '6px',
            overflow: 'hidden',
            display: 'flex',
            background: 'black',
            aspectRatio: '16/9'
        } }, { children: _jsx("video", { 
            // crossOrigin={'use-credentials'}
            src: props.src, style: { height: '100%', width: '100%' }, controls: true }) })));
};
