var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { CourseView } from './views/course';
import { Dashboard } from './views/dashboard';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
console.log(process.env);
var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');
export var API_URL = process.env.NODE_ENV === 'production' ? 'https://api.internetlearning.io' : 'http://localhost:8030';
// options={{clientSecret: process.env.STRIPE_CLIENT_SECRET || '{{CLIENT_SECRET}}'}}
function App() {
    return (_jsx(Elements, __assign({ stripe: stripePromise }, { children: _jsx(Box, __assign({ sx: { maxHeight: '100vh', flex: 1, bgcolor: 'background.default', width: '100vw', display: 'flex', overflow: 'hidden' } }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "*", element: _jsx(Dashboard, {}) }), _jsx(Route, { path: "certificates/:id/*", element: _jsx(CourseView, {}) })] }) })) })));
}
export default App;
