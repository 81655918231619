var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Paper, Typography, Divider, IconButton, styled } from '@mui/material';
import { CourseCard } from '../../../../components/course-card';
import moment from 'moment';
import { Add } from '@mui/icons-material';
var BaseCourseList = function (props) {
    var _a;
    return (_jsx(Box, __assign({ className: props.className, sx: { flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column' } }, { children: _jsxs(Paper, __assign({ className: "course-list__container", sx: { flex: 1, display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'hidden', margin: '12px' } }, { children: [_jsx(Box, __assign({ sx: { padding: '6px', bgcolor: 'secondary.main', color: 'background.default', } }, { children: _jsx(Typography, __assign({ sx: { marginLeft: '12px' }, fontWeight: "bold" }, { children: props.title || "Course List" })) })), _jsx(Divider, {}), _jsx(Box, __assign({ className: "course-list", sx: {
                        flex: 1,
                        minHeight: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        // padding: '12px', 
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        overflowY: 'auto',
                        // flexWrap: 'wrap',
                        // justifyContent: 'center'
                    } }, { children: (_a = props.courses) === null || _a === void 0 ? void 0 : _a.map(function (course) { return (_jsx(CourseCard, { id: course.id, name: course.name, progress: course.progress, lastActive: moment(course.lastTouched).format('DD/MM/YY'), onClick: function () { var _a; return (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, course); } })); }) })), props.onCreate ? (_jsx(IconButton, __assign({ size: 'large', onClick: props.onCreate, sx: { position: 'absolute', bottom: 24, right: 24, color: 'background.default', '&:hover': { bgcolor: 'secondary.light' }, bgcolor: 'secondary.main' } }, { children: _jsx(Add, { fontSize: 'inherit' }) }))) : null] })) })));
};
export var CourseList = styled(BaseCourseList)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media only screen and (max-width: 768px) {\n        .course-list {\n            padding-left: 6px;\n            padding-right: 6px;\n        }\n\n        .course-list__container{\n            margin: 0;\n        }\n    }\n"], ["\n    @media only screen and (max-width: 768px) {\n        .course-list {\n            padding-left: 6px;\n            padding-right: 6px;\n        }\n\n        .course-list__container{\n            margin: 0;\n        }\n    }\n"])));
var templateObject_1;
