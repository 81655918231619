var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Divider, TextField } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { StoreCard } from 'edutech-frontend/src/components/course-card/store';
import { styled } from '@mui/material';
var BaseStoreList = function (props) {
    var _a, _b;
    var navigate = useNavigate();
    var _c = useState(''), search = _c[0], setSearch = _c[1];
    var data = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query GetCourses {\n            courses {\n                id\n                name\n\n                duration\n\n                shortDescription\n            }\n        }\n    "], ["\n        query GetCourses {\n            courses {\n                id\n                name\n\n                duration\n\n                shortDescription\n            }\n        }\n    "])))).data;
    var searchFilter = function (item) {
        var _a, _b;
        if (!search || search.length < 1)
            return true;
        return ((_b = (_a = item.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.indexOf(search === null || search === void 0 ? void 0 : search.toLowerCase())) > -1;
    };
    return (_jsxs(Box, __assign({ className: props.className, sx: { flex: 1, display: 'flex', flexDirection: 'column' } }, { children: [_jsx(Box, __assign({ sx: { display: 'flex', bgcolor: 'background.paper', paddingTop: '12px', paddingBottom: '12px', justifyContent: 'center' } }, { children: _jsx(TextField, { className: "store-search", fullWidth: true, value: search, onChange: function (e) { return setSearch(e.target.value); }, sx: { borderRadius: '12px', width: '80%' }, size: "small", label: "Search ..." }) })), _jsx(Divider, {}), _jsx(Box, __assign({ className: "store-list-container", sx: {
                    flex: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    overflowY: 'auto',
                    marginLeft: '10%',
                    marginRight: '10%',
                    justifyContent: 'center',
                    padding: '6px'
                } }, { children: (_b = (_a = data === null || data === void 0 ? void 0 : data.courses) === null || _a === void 0 ? void 0 : _a.filter(searchFilter)) === null || _b === void 0 ? void 0 : _b.map(function (course) { return (_jsx(StoreCard, __assign({ onClick: function () {
                        navigate("/certificates/".concat(course.id));
                    } }, course))); }) }))] })));
};
export var StoreList = styled(BaseStoreList)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    @media only screen and (max-width: 768px) {\n\n        .store-search {\n            width: 80vw;\n        }\n\n        .store-list-container {\n            margin-left: 0; \n            margin-right: 0;\n\n            justify-content: flex-start;\n\n            flex-wrap: nowrap;\n            flex-direction: column;\n        }\n\n        .store-list-container .course-description {\n            display: none;\n        }\n\n        .store-list-container .store-card {\n            width: unset;\n            min-height: 100px;\n\n            font-size: 12px;\n        }\n    }\n"], ["\n    @media only screen and (max-width: 768px) {\n\n        .store-search {\n            width: 80vw;\n        }\n\n        .store-list-container {\n            margin-left: 0; \n            margin-right: 0;\n\n            justify-content: flex-start;\n\n            flex-wrap: nowrap;\n            flex-direction: column;\n        }\n\n        .store-list-container .course-description {\n            display: none;\n        }\n\n        .store-list-container .store-card {\n            width: unset;\n            min-height: 100px;\n\n            font-size: 12px;\n        }\n    }\n"])));
var templateObject_1, templateObject_2;
