var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@mui/material';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { CertificateOutcomes, CertificateSummary, MultichoiceAssessment, VideoSummary } from './parts';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import moment from 'moment';
import { CourseImage } from '../../components/course-image';
import { API_URL } from '../../App';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { styled } from '@mui/material';
var BaseCoursePreview = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var navigate = useNavigate();
    // const duration = useMemo(() => {
    //     let duration = moment.duration(props.course?.duration, 'seconds');
    //     if(duration.asMinutes() > 60){
    //         return duration.asHours()
    //     }
    // }, [props.course?.duration])
    var registerCourse = useMutation(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        mutation RegisterCourse($course: ID){\n            registerCourse(course: $course)\n        }\n    "], ["\n        mutation RegisterCourse($course: ID){\n            registerCourse(course: $course)\n        }\n    "]))), {
        refetchQueries: ['GetCourse']
    })[0];
    var imageSrc = useMemo(function () {
        var _a, _b;
        if ((_a = props.course) === null || _a === void 0 ? void 0 : _a.image) {
            return "".concat(API_URL, "/api/").concat((_b = props.course) === null || _b === void 0 ? void 0 : _b.id, "/image");
        }
        return undefined;
    }, [(_a = props.course) === null || _a === void 0 ? void 0 : _a.image]);
    return (_jsxs(Paper, __assign({ className: props.className, elevation: 5, sx: {
            borderRadius: '12px',
            padding: '48px',
            overflowY: 'auto',
            marginLeft: '8vw',
            marginRight: '8vw',
            display: 'flex',
            flexDirection: 'column',
            flex: 1
        } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '24px', justifyContent: 'space-between' } }, { children: [_jsxs(Box, { children: [_jsx(Typography, __assign({ fontWeight: "bold", fontSize: "23px" }, { children: (_b = props.course) === null || _b === void 0 ? void 0 : _b.name })), _jsxs(Typography, __assign({ fontWeight: "bold" }, { children: ["Duration: ", moment.duration((_c = props.course) === null || _c === void 0 ? void 0 : _c.duration, 'seconds').asMinutes(), " minutes"] })), _jsx(Typography, { children: (_d = props.course) === null || _d === void 0 ? void 0 : _d.shortDescription })] }), _jsx(CourseImage, { src: imageSrc })] })), _jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsx(CertificateSummary, { children: (_e = props.course) === null || _e === void 0 ? void 0 : _e.description }), _jsx(CertificateOutcomes, { outcomes: ((_f = props.course) === null || _f === void 0 ? void 0 : _f.outcomes) || [] }), _jsx(VideoSummary, { videos: ((_g = props.course) === null || _g === void 0 ? void 0 : _g.stages) || [] }), _jsx(MultichoiceAssessment, {})] })), _jsx(Box, __assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, __assign({ disabled: (_h = props.course) === null || _h === void 0 ? void 0 : _h.owned, onClick: function () {
                        var _a;
                        registerCourse({ variables: { course: (_a = props.course) === null || _a === void 0 ? void 0 : _a.id } }).then(function () {
                            navigate('register');
                        });
                        // navigate('register')
                    }, sx: { marginTop: '12px' }, size: "large", color: "secondary", variant: "contained" }, { children: ((_j = props.course) === null || _j === void 0 ? void 0 : _j.owned) ? "Registered" : "Register" })) }))] })));
};
export var CoursePreview = styled(BaseCoursePreview)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    @media only screen and (max-width: 768px) {\n        margin-left: 0;\n        margin-right: 0;\n\n        padding: 24px;\n    }\n"], ["\n    @media only screen and (max-width: 768px) {\n        margin-left: 0;\n        margin-right: 0;\n\n        padding: 24px;\n    }\n"])));
var templateObject_1, templateObject_2;
