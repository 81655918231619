var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { CertificateCard } from '../../../../components/certificate-card';
import { PurchaseCertificateModal } from '../purchase-certificate';
import { API_URL } from 'edutech-frontend/src/App';
import { styled } from '@mui/material';
var BaseCertificateList = function (props) {
    var _a;
    var _b = useState(null), purchaseModalOpen = _b[0], openPurchaseModal = _b[1];
    return (_jsxs(Paper, __assign({ className: props.className, sx: { flex: 1, minHeight: 0, overflow: 'hidden', margin: '12px', display: 'flex', flexDirection: 'column' } }, { children: [_jsx(PurchaseCertificateModal, { open: Boolean(purchaseModalOpen), course: { id: purchaseModalOpen }, onClose: function () {
                    openPurchaseModal(null);
                } }), _jsx(Box, __assign({ sx: { bgcolor: 'secondary.main', color: 'background.default', padding: '6px' } }, { children: _jsx(Typography, __assign({ sx: { marginLeft: '12px' }, fontWeight: "bold" }, { children: "Completed Certificates" })) })), _jsx(Box, __assign({ className: "certificate-list", sx: { flex: 1, paddingLeft: '24px', paddingRight: '24px', display: 'flex', flexDirection: 'column' } }, { children: (_a = props.history) === null || _a === void 0 ? void 0 : _a.map(function (history_item) { return (_jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: _jsx(CertificateCard, __assign({ onClick: function () {
                            if (history_item.certified) {
                                // /:course/certificate
                                var link = document.createElement('a');
                                link.href = "".concat(API_URL, "/api/").concat(history_item.id, "/certificate.pdf");
                                link.setAttribute('download', "".concat(history_item.name, ".pdf"));
                                link.setAttribute('target', '_blank');
                                document.body.appendChild(link);
                                link.click();
                            }
                            else {
                                openPurchaseModal(history_item.id);
                            }
                        } }, history_item)) }))); }) }))] })));
};
export var CertificateList = styled(BaseCertificateList)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\n    @media only screen and (max-width: 768px) {\n        margin: 0;\n\n        margin-top: 6px;\n        \n        .certificate-list {\n            padding-left: 6px;\n            padding-right: 6px;\n        }\n    }\n"], ["\n\n    @media only screen and (max-width: 768px) {\n        margin: 0;\n\n        margin-top: 6px;\n        \n        .certificate-list {\n            padding-left: 6px;\n            padding-right: 6px;\n        }\n    }\n"])));
var templateObject_1;
