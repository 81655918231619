var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Paper, TextField } from '@mui/material';
import Logo from '../../assets/logo-dark.svg';
import { API_URL } from "../../App";
import Background from './background.svg';
// import { LoginView } from "edutech-shared-components";
import { Link } from "react-router-dom";
export var Login = function (props) {
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var _c = useState(false), rememberMe = _c[0], setRememberMe = _c[1];
    var tryLogin = function (auth) { return __awaiter(void 0, void 0, void 0, function () {
        var email, password, rememberMe, res, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    email = auth.email, password = auth.password, rememberMe = auth.rememberMe;
                    props.onAuthenticating(true, false);
                    return [4 /*yield*/, fetch("".concat(API_URL, "/api/auth"), {
                            method: 'POST',
                            headers: {
                                'Content-Type': "application/json"
                            },
                            body: JSON.stringify({
                                email: email,
                                password: password,
                                rememberMe: rememberMe
                            }),
                            credentials: 'include'
                        })];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    data = _a.sent();
                    if (data.error) {
                        props.onAuthenticating(false, false);
                    }
                    else if (data.success) {
                        props.onAuthenticating(false, true);
                    }
                    console.log({ data: data });
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, __assign({ sx: { position: 'relative', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' } }, { children: [_jsxs(Paper, __assign({ sx: { display: 'flex', minWidth: '30vw', padding: '12px', flexDirection: 'column' } }, { children: [_jsx(Box, __assign({ sx: { marginBottom: '24px', display: 'flex', justifyContent: 'center' } }, { children: _jsx("img", { alt: "ILL Logo", src: Logo, style: { height: '70px' } }) })), _jsx(TextField, { value: email, onChange: function (e) { return setEmail(e.target.value); }, sx: { marginTop: '6px', marginBottom: '6px' }, size: "small", label: "Email" }), _jsx(TextField, { value: password, onChange: function (e) { return setPassword(e.target.value); }, size: "small", onKeyDown: function (e) {
                            if (e.key === 'Enter') {
                                // tryLogin();
                                tryLogin({ email: email, password: password, rememberMe: rememberMe });
                            }
                        }, sx: { marginTop: '6px', marginBottom: '6px' }, label: "Password", type: "password" }), _jsx(FormControlLabel, { label: "Remember me", control: _jsx(Checkbox, { checked: rememberMe, onChange: function (e) { return setRememberMe(e.target.checked); } }) }), _jsx(Box, __assign({ sx: { marginBottom: '12px' } }, { children: _jsxs(Button, __assign({ disabled: props.authenticating, onClick: function () {
                                tryLogin({ email: email, password: password, rememberMe: rememberMe });
                                // navigate('/dashboard')
                            }, fullWidth: true, variant: "contained", color: "secondary" }, { children: [props.authenticating ? _jsx(CircularProgress, { size: "small" }) : "", " Login"] })) })), _jsx(Divider, {}), _jsxs(Box, __assign({ sx: { '& > a': { padding: '6px', color: 'black' }, '& > a:hover': { background: '#dfdfdf', padding: '6px', borderRadius: '12px', opacity: 0.8 }, display: 'flex', marginTop: '6px' } }, { children: [_jsx(Link, __assign({ style: { flex: 1, textAlign: 'center', textDecoration: 'none' }, to: "forgot" }, { children: "Forgot password" })), _jsx(Link, __assign({ style: { flex: 1, textAlign: 'center', textDecoration: 'none' }, to: "signup" }, { children: "Create account" }))] }))] })), _jsx(Box, { sx: { position: 'absolute', zIndex: -1, top: 0, left: 0, right: 0, bottom: 0, backgroundSize: '110px', backgroundImage: "url(".concat(Background, ")"), } })] })));
};
