var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, DialogTitle } from '@mui/material';
import { Typography } from '@mui/material';
import { FormControl } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Button } from '@mui/material';
import { Paper } from '@mui/material';
import { MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { DialogContent } from '@mui/material';
import { Dialog } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useConfirm } from 'edutech-frontend/src/hooks/useConfirmModal';
import React, { useState } from 'react';
export var PurchaseCertificateModal = function (props) {
    var elements = useElements();
    var stripe = useStripe();
    var _a = useState(null), error = _a[0], setError = _a[1];
    var data = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query PaymentMethods {\n            me {\n                paymentMethods {\n                    id\n                    last4\n                    \n                }\n            }\n        }\n    "], ["\n        query PaymentMethods {\n            me {\n                paymentMethods {\n                    id\n                    last4\n                    \n                }\n            }\n        }\n    "])))).data;
    var _b = useState(false), createCard = _b[0], setCreateCard = _b[1];
    console.log({ data: data });
    var purchaseCertificate = useMutation(gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        mutation PurchaseCertificate ($course: ID!, $paymentMethod: ID!){\n            purchaseCertificate(course: $course, paymentMethod: $paymentMethod)\n        }\n    "], ["\n        mutation PurchaseCertificate ($course: ID!, $paymentMethod: ID!){\n            purchaseCertificate(course: $course, paymentMethod: $paymentMethod)\n        }\n    "]))), {
        refetchQueries: ['GetDashboardInfo']
    })[0];
    var createPaymentMethod = useMutation(gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        mutation CreatePaymentMethod ($source: String){\n            addPaymentMethod(source: $source){\n                id\n\n                last4\n            }\n        }\n    "], ["\n        mutation CreatePaymentMethod ($source: String){\n            addPaymentMethod(source: $source){\n                id\n\n                last4\n            }\n        }\n    "]))), {
        refetchQueries: ['PaymentMethods']
    })[0];
    var _c = useState(''), selectedPayment = _c[0], setSelectedPayment = _c[1];
    var purchase = function () {
        var _a;
        purchaseCertificate({
            variables: {
                course: (_a = props.course) === null || _a === void 0 ? void 0 : _a.id,
                paymentMethod: selectedPayment
            }
        }).then(function (data) {
            var _a;
            console.log({ data: data });
            (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
        });
    };
    var _d = useConfirm("Are you sure you want to purchase this item?", purchase), ConfirmModal = _d[0], confirmedPurchase = _d[1];
    var renderPaymentSelect = function () {
        var _a, _b;
        return (_jsxs(FormControl, __assign({ size: 'small', fullWidth: true }, { children: [_jsx(InputLabel, { children: "Payment method" }), _jsxs(Select, __assign({ onChange: function (e) {
                        if (e.target.value === 'create') {
                            setCreateCard(true);
                        }
                        else {
                            setSelectedPayment(e.target.value);
                        }
                    }, value: selectedPayment, label: "Payment method" }, { children: [(_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.paymentMethods) === null || _b === void 0 ? void 0 : _b.map(function (method) { return (_jsxs(MenuItem, __assign({ value: method.id }, { children: ["**** **** **** ", method.last4, " "] }))); }), _jsx(MenuItem, __assign({ value: "create" }, { children: "Add card" }))] }))] })));
    };
    var renderCardCreate = function () {
        return (_jsxs(Paper, __assign({ sx: { padding: '6px', overflow: 'hidden' } }, { children: [_jsx(Typography, __assign({ sx: { marginBottom: '12px' } }, { children: "Add new card" })), error ? _jsx(Typography, __assign({ color: "red", fontSize: "small" }, { children: error })) : null, _jsx(Box, { children: _jsx(CardNumberElement, { options: {
                            style: {
                                base: {
                                    fontSize: '18px'
                                }
                            }
                        } }) }), _jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '12px', paddingTop: '12px' } }, { children: [_jsx(Box, __assign({ sx: { flex: 0.7 } }, { children: _jsx(CardExpiryElement, { options: {
                                    style: {
                                        base: {
                                            fontSize: '18px'
                                        }
                                    }
                                } }) })), _jsx(Box, __assign({ sx: { flex: 0.3 } }, { children: _jsx(CardCvcElement, { options: {
                                    style: {
                                        base: {
                                            fontSize: '18px'
                                        }
                                    }
                                } }) }))] })), _jsx(Box, __assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: _jsx(Button, __assign({ onClick: function () {
                            if (!elements)
                                return;
                            var cardElement = elements.getElement('cardNumber');
                            if (!cardElement)
                                return;
                            stripe === null || stripe === void 0 ? void 0 : stripe.createSource(cardElement, {
                                type: 'card'
                            }).then(function (data) {
                                var _a, _b, _c;
                                if ((_a = data.error) === null || _a === void 0 ? void 0 : _a.message) {
                                    return setError(data.error.message);
                                }
                                // data.source.t
                                if ((_b = data.source) === null || _b === void 0 ? void 0 : _b.id) {
                                    createPaymentMethod({
                                        variables: {
                                            source: (_c = data === null || data === void 0 ? void 0 : data.source) === null || _c === void 0 ? void 0 : _c.id
                                        }
                                    }).then(function () {
                                        setCreateCard(false);
                                    });
                                }
                            });
                        }, size: "small", variant: "contained" }, { children: "Add card" })) }))] })));
    };
    return (_jsxs(Dialog, __assign({ fullWidth: true, open: props.open }, { children: [_jsx(ConfirmModal, {}), _jsx(DialogTitle, { children: "Purchase Certificate" }), _jsxs(DialogContent, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx(Typography, __assign({ sx: { marginTop: '12px' } }, { children: "Purchase certificate" })), _jsx(Box, __assign({ sx: { marginTop: '12px' } }, { children: _jsx(Typography, { children: "Price: $9" }) })), _jsxs(Box, __assign({ sx: { marginTop: '12px' } }, { children: [createCard ? renderCardCreate() : renderPaymentSelect(), _jsx(Typography, {})] }))] })), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: props.onClose }, { children: "Cancel" })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: confirmedPurchase }, { children: "Purchase" }))] })] })));
};
var templateObject_1, templateObject_2, templateObject_3;
