var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Home } from '../home';
import { Header } from '../../components/header';
import { StoreList } from '../store';
import { ProfileView } from '../profile';
export var Dashboard = function () {
    return (_jsxs(Box, __assign({ sx: { height: '100vh', width: '100vw', overflow: 'hidden', flex: 1, display: 'flex', bgcolor: 'background.default', flexDirection: 'column' } }, { children: [_jsx(Header, {}), _jsx(Box, __assign({ sx: { flex: 1, maxHeight: "calc(100% - 66px)", display: 'flex' } }, { children: _jsx(Routes, { children: _jsxs(Route, __assign({ path: "", element: _jsx(Outlet, {}) }, { children: [_jsx(Route, { path: "", element: _jsx(Home, {}) }), _jsx(Route, __assign({ path: "certificates", element: _jsx(Outlet, {}) }, { children: _jsx(Route, { path: "", element: _jsx(StoreList, {}) }) })), _jsx(Route, { path: "profile/*", element: _jsx(ProfileView, {}) })] })) }) }))] })));
};
