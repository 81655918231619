var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useMutation } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { List, Box, Typography, ListItem, Paper, Divider } from '@mui/material';
import React, { useState } from 'react';
import { CardModal } from './card-modal';
export var PaymentInfo = function (props) {
    var _a;
    var _b = useState(false), modalOpen = _b[0], openModal = _b[1];
    var createPaymentMethod = useMutation(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        mutation CreatePaymentMethod ($source: String){\n            addPaymentMethod(source: $source){\n                id\n\n                last4\n            }\n        }\n    "], ["\n        mutation CreatePaymentMethod ($source: String){\n            addPaymentMethod(source: $source){\n                id\n\n                last4\n            }\n        }\n    "]))), {
        refetchQueries: ['GetProfile']
    })[0];
    var deletePaymentMethod = useMutation(gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        mutation DeletePaymentMethod ($id: ID){\n            deletePaymentMethod(id: $id){\n                id\n            }\n        }\n    "], ["\n        mutation DeletePaymentMethod ($id: ID){\n            deletePaymentMethod(id: $id){\n                id\n            }\n        }\n    "]))), {
        refetchQueries: ['GetProfile']
    })[0];
    return (_jsxs(Paper, __assign({ sx: { display: 'flex', flexDirection: 'column', overflow: 'hidden', marginTop: '12px' } }, { children: [_jsx(CardModal, { open: modalOpen, onClose: function () { return openModal(false); }, onSubmit: function (sourceId) {
                    createPaymentMethod({
                        variables: {
                            source: sourceId
                        }
                    }).then(function () { return openModal(false); });
                } }), _jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'secondary.main', color: 'background.default', padding: '12px' } }, { children: [_jsx(Typography, { children: "Payment info" }), _jsx(Button, __assign({ onClick: function () { return openModal(true); }, size: "small" }, { children: "Add" }))] })), _jsx(Divider, {}), _jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', padding: '12px' } }, { children: _jsx(List, __assign({ disablePadding: true }, { children: _jsx(ListItem, __assign({ sx: { display: 'flex', padding: '12px' } }, { children: (_a = props.methods) === null || _a === void 0 ? void 0 : _a.map(function (paymentMethod) { return (_jsxs(Paper, __assign({ elevation: 3, sx: { display: 'flex', paddingLeft: '12px', flex: 1, alignItems: 'center' } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1, paddingTop: '6px', paddingBottom: '6px', display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsxs(Typography, __assign({ fontWeight: "bold" }, { children: ["**** **** **** ", paymentMethod.last4] })), _jsxs(Typography, { children: [paymentMethod.exp_month, "/", paymentMethod.exp_year] })] })), _jsx(IconButton, __assign({ onClick: function () {
                                        deletePaymentMethod({
                                            variables: {
                                                id: paymentMethod.id
                                            }
                                        });
                                    }, color: "error" }, { children: _jsx(Delete, {}) }))] }))); }) })) })) }))] })));
};
var templateObject_1, templateObject_2;
