var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { ListItem } from "@mui/material";
import { List } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
export var CertificateTitle = function (props) {
    return (_jsx(Typography, __assign({ sx: { marginBottom: '12px' }, fontSize: "23px" }, { children: props.title })));
};
export var CertificateSummary = function (props) {
    return (_jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '24px', flexDirection: 'column', alignItems: 'flex-start' } }, { children: [_jsx(CertificateTitle, { title: "Certifcate Summary" }), _jsx(Typography, __assign({ sx: { paddingLeft: '12px' } }, { children: props.children }))] })));
};
export var CertificateOutcomes = function (props) {
    var _a, _b, _c;
    return (_jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '24px', flexDirection: 'column', alignItems: 'flex-start' } }, { children: [_jsx(CertificateTitle, { title: "Certificate Learning Outcomes" }), _jsx(List, __assign({ disablePadding: true }, { children: (_c = (_b = (_a = props.outcomes) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; })) === null || _c === void 0 ? void 0 : _c.map(function (outcome) { return (_jsxs(ListItem, __assign({ sx: { display: 'flex', marginBottom: '12px', padding: '0px' } }, { children: [_jsx("div", { style: { width: 2, height: 2, marginRight: '5px', marginLeft: '5px', borderRadius: '100%', background: 'black' } }), _jsx(Typography, { children: outcome.text })] }))); }) })), _jsx(Typography, { children: props.children })] })));
};
export var MultichoiceAssessment = function (props) {
    return (_jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '24px', flexDirection: 'column', alignItems: 'flex-start' } }, { children: [_jsx(CertificateTitle, { title: "Multi-choice Assessment" }), _jsx(Typography, __assign({ sx: { paddingLeft: '12px' } }, { children: "When you have completed viewing the videos and passed the multi choice questions at the end of each video you will be able to download a digital certificate. This will confirm your participation in the learning and demonstrate to employers your initiative in developing this key workplace skill." }))] })));
};
export var VideoSummary = function (props) {
    var _a;
    return _jsxs(Box, __assign({ sx: { display: 'flex', marginBottom: '24px', flexDirection: 'column', alignItems: 'flex-start' } }, { children: [_jsx(CertificateTitle, { title: "Video Summary" }), _jsx(Box, __assign({ sx: { paddingLeft: '12px' } }, { children: (_a = props.videos) === null || _a === void 0 ? void 0 : _a.map(function (video, ix) { return (_jsxs(Typography, { children: [ix + 1, ". ", video.name] })); }) }))] }));
};
