var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, styled, Typography } from '@mui/material';
import React from 'react';
import LogoImage from '../../assets/logoimage.svg';
import LogoText from '../../assets/logotext.svg';
import { useNavigate } from 'react-router-dom';
import { HeaderTabs } from './tabs';
import { gql, useQuery } from '@apollo/client';
var BaseHeader = function (props) {
    var _a;
    var navigate = useNavigate();
    // const path = useResolv
    var data = useQuery(gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query {\n            me {\n                name\n            }\n        }\n    "], ["\n        query {\n            me {\n                name\n            }\n        }\n    "])))).data;
    return (_jsxs(Box, __assign({ className: props.className, sx: { display: 'flex', backgroundColor: '#0A1229' } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1, display: 'flex', alignItems: 'center' } }, { children: [_jsx("img", { alt: "ILL LogoImage", onClick: function () { return window.location.href = 'https://www.internetlearning.io'; }, style: { height: '45px', marginLeft: '12px', cursor: 'pointer' }, src: LogoImage }), _jsx("img", { className: 'logo-text', alt: "ILL LogoText", onClick: function () { return window.location.href = 'https://www.internetlearning.io'; }, src: LogoText, style: { cursor: 'pointer', height: '45px', padding: '6px' } }), _jsx(HeaderTabs, {})] })), _jsx(Box, __assign({ sx: { flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' } }, { children: _jsx(Typography, __assign({ sx: { color: 'text.default' }, fontWeight: "bold", fontSize: "28px" }, { children: props === null || props === void 0 ? void 0 : props.title })) })), _jsx(Box, __assign({ sx: { flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '12px' } }, { children: _jsx(Button, __assign({ sx: {
                        marginLeft: "12px",
                    }, onClick: function () {
                        navigate('/profile');
                    }, variant: 'contained', color: "secondary" }, { children: ((_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.name) || "Profile" })) }))] })));
};
export var Header = styled(BaseHeader)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    @media only screen and (max-width: 768px) {\n    \n        .logo-text {\n            display: none;\n        }\n    }\n"], ["\n    @media only screen and (max-width: 768px) {\n    \n        .logo-text {\n            display: none;\n        }\n    }\n"])));
var templateObject_1, templateObject_2;
