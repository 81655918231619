var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dashboard, ShoppingCart } from '@mui/icons-material';
import { styled } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import React from 'react';
import { resolvePath, useNavigate, useLocation, matchPath } from 'react-router-dom';
var useRouteMatch = function (patterns) {
    var pathname = useLocation().pathname;
    for (var i = 0; i < patterns.length; i += 1) {
        var pattern = resolvePath(patterns[i], '/').pathname;
        var possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }
    return null;
};
var BaseHeaderTabs = function (props) {
    // const path = useMatch();
    var _a;
    var navigate = useNavigate();
    var routeMatch = useRouteMatch(['', 'certificates']);
    var currentTab = (_a = routeMatch === null || routeMatch === void 0 ? void 0 : routeMatch.pattern) === null || _a === void 0 ? void 0 : _a.path;
    // console.log({location: useLocation()})
    return (_jsxs(Tabs
    // sx={{marginLeft: '12px'}}
    , __assign({ 
        // sx={{marginLeft: '12px'}}
        className: props.className, sx: {
            marginLeft: '12px',
            color: 'white'
        }, 
        // textColor='#fff'
        // indicatorColor='#fff'
        value: currentTab, onChange: function (e, newValue) {
            navigate(newValue);
        } }, { children: [_jsx(Tab, { sx: { minHeight: '50px', color: 'white', textTransform: 'none' }, value: "/", icon: _jsx(Dashboard, { fontSize: 'small' }), label: "Dashboard" }), _jsx(Tab, { sx: { minHeight: '50px', color: 'white', textTransform: 'none' }, iconPosition: 'start', value: "/certificates", icon: _jsx(ShoppingCart, { fontSize: "small" }), label: "Certificates" })] })));
};
// import 
export var HeaderTabs = styled(BaseHeaderTabs)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  svg {\n    display: none;\n  }\n\n  @media only screen and (max-width: 768px) {\n    svg {\n      display: inline-block;\n    }\n\n    button {\n      min-width: 33px;\n      font-size: 0;\n      padding: 0;\n    }\n\n    .MuiTab-iconWrapper {\n      margin: 0;\n    }\n  }\n"], ["\n  svg {\n    display: none;\n  }\n\n  @media only screen and (max-width: 768px) {\n    svg {\n      display: inline-block;\n    }\n\n    button {\n      min-width: 33px;\n      font-size: 0;\n      padding: 0;\n    }\n\n    .MuiTab-iconWrapper {\n      margin: 0;\n    }\n  }\n"])));
var templateObject_1;
