var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
var BaseCourseCard = function (props) {
    useEffect(function () {
        // fetch(`https://source.unsplash.com/300x300?id=${props.id}`, {
        //     cache: 'no-cache',
        //     // redirect: 'manual'
        // }).then((r) => {
        //     console.log(r)
        // })
    }, []);
    return (_jsxs(Paper, __assign({ className: props.className, elevation: 5, onClick: props.onClick, sx: {
            // bgcolor: 'primary.main',
            borderRadius: '12px',
            minHeight: '100px',
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            padding: '24px',
            margin: '12px',
            overflow: 'hidden',
        } }, { children: [_jsx(Box, { className: "course-card__image", sx: { height: 80, width: 80, alignSelf: 'center', marginLeft: '12px', marginRight: '12px', backgroundImage: "url(https://api.internetlearning.io/api/".concat(props.id, "/image)"), backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' } }), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: 1, paddingTop: '12px', paddingRight: '12px', paddingBottom: '12px' } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsx(Typography, __assign({ className: "course-card__name", fontWeight: "bold", fontSize: "lg" }, { children: props.name })), _jsxs(Typography, __assign({ sx: { marginTop: '12px' }, fontSize: "15px" }, { children: ["Last active: ", props.lastActive] }))] })), _jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, __assign({ fontSize: '12px' }, { children: "Progress" })), _jsx(LinearProgress, { value: props.progress || 0, sx: { flex: 1, height: 7, marginLeft: '6px', borderRadius: 7 }, variant: 'determinate' })] }))] }))] })));
};
export var CourseCard = styled(BaseCourseCard)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media only screen and (max-width: 768px) {\n        margin: 6px;\n        padding: 6px;\n\n        .course-card__name {\n            font-size: 0.9rem;\n        }\n\n        .course-card__image{\n            width: 50px;\n            height: 50px\n        }\n    }\n\n"], ["\n    @media only screen and (max-width: 768px) {\n        margin: 6px;\n        padding: 6px;\n\n        .course-card__name {\n            font-size: 0.9rem;\n        }\n\n        .course-card__image{\n            width: 50px;\n            height: 50px\n        }\n    }\n\n"])));
var templateObject_1;
