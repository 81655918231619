var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { Paper } from '@mui/material';
import React from 'react';
export var ContactUs = function () {
    return (_jsxs(Paper, __assign({ sx: { overflow: 'hidden', marginTop: '12px', } }, { children: [_jsx(Box, __assign({ sx: { bgcolor: 'secondary.main', color: 'background.default', padding: '12px' } }, { children: _jsx(Typography, { children: "Contact Us" }) })), _jsx(Box, __assign({ sx: { padding: '12px' } }, { children: _jsx(Typography, { children: "Please contact us for any support enquiries at support@internetlearning.io" }) }))] })));
};
