import { useEffect, useMemo, useState } from 'react';
export var useCourseNav = function (course, finish, navigation) {
    var _a = useState(0), activeStep = _a[0], setActiveStage = _a[1];
    var _b = useState(0), activeQuestion = _b[0], setActiveQuestion = _b[1];
    var normStep = Math.floor(activeStep / 2);
    var isQuestionTime = !((activeStep % 2) === 0);
    useEffect(function () {
        navigation === null || navigation === void 0 ? void 0 : navigation(activeStep);
    }, [activeStep]);
    var activeStage = useMemo(function () {
        var _a, _b, _c;
        return (_c = (_b = (_a = course === null || course === void 0 ? void 0 : course.stages) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; })) === null || _c === void 0 ? void 0 : _c[normStep || 0]; //find((a, ix) => props.activeStage === ix);
    }, [course, normStep]);
    var canNext = function () {
        var _a, _b;
        var hasQuestions = (((_a = course === null || course === void 0 ? void 0 : course.stages) === null || _a === void 0 ? void 0 : _a[((_b = course === null || course === void 0 ? void 0 : course.stages) === null || _b === void 0 ? void 0 : _b.length) - 1].questions) || []).length > 0;
        console.log({ activeStep: activeStep, hasQuestions: hasQuestions, stage1: ((((course === null || course === void 0 ? void 0 : course.stages) || []).length * 2) - 1), staage2: (((course === null || course === void 0 ? void 0 : course.stages) || []).length * 2) - 2 });
        return activeStep < (hasQuestions ? (((course === null || course === void 0 ? void 0 : course.stages) || []).length * 2) - 1 : (((course === null || course === void 0 ? void 0 : course.stages) || []).length * 2) - 2);
    };
    var next = function () {
        var _a;
        if (!isQuestionTime && canNext()) {
            setActiveStage(function (q) {
                var _a, _b, _c;
                if (((_a = course === null || course === void 0 ? void 0 : course.stages) === null || _a === void 0 ? void 0 : _a[Math.floor((q + 1) / 2)]) && (((_c = (_b = course === null || course === void 0 ? void 0 : course.stages) === null || _b === void 0 ? void 0 : _b[Math.floor((q + 1) / 2)]) === null || _c === void 0 ? void 0 : _c.questions) || []).length < 1) {
                    return q + 2;
                }
                else {
                    return q + 1;
                }
            });
            // setQuestionTime(false)
            // props.onStageChange?.()
        }
        else if (isQuestionTime) {
            if (activeQuestion < (((_a = activeStage === null || activeStage === void 0 ? void 0 : activeStage.questions) === null || _a === void 0 ? void 0 : _a.length) || 0) - 1) {
                setActiveQuestion(function (q) { return q + 1; });
            }
            else if (canNext()) {
                setActiveStage(function (q) { return q + 1; });
                setActiveQuestion(0);
            }
            else {
                //Finish
                // alert("Finish")
                finish === null || finish === void 0 ? void 0 : finish(course);
                // finishCourse({ variables: { course: course?.id } }).then(() => {
                //     // alert("Go to finish page")
                //     navigate('../complete')
                // }).catch((e: any) => {
                //     console.error("Show error", e.message)
                // })
            }
            // setQuestionTime(true)
        }
        else {
            finish === null || finish === void 0 ? void 0 : finish(course);
            // finishCourse({ variables: { course: course?.id } }).then(() => {
            //     // alert("Go to finish page")
            //     navigate('../complete')
            // }).catch((e: any) => {
            //     console.error("Show error", e.message)
            // })
        }
    };
    var canPrev = function () {
        return activeStep > 0;
    };
    var prev = function () {
        var _a, _b, _c;
        if (!isQuestionTime && canPrev()) {
            // setQuestionTime(false)
            setActiveStage(function (q) {
                var _a, _b, _c;
                if (((_a = course === null || course === void 0 ? void 0 : course.stages) === null || _a === void 0 ? void 0 : _a[Math.floor((q - 1) / 2)]) && (((_c = (_b = course === null || course === void 0 ? void 0 : course.stages) === null || _b === void 0 ? void 0 : _b[Math.floor((q - 1) / 2)]) === null || _c === void 0 ? void 0 : _c.questions) || []).length < 1) {
                    return q - 2;
                }
                else {
                    return q - 1;
                }
            });
            if (!((activeStep - 1) % 2 === 0))
                setActiveQuestion((((_c = (_b = (_a = course === null || course === void 0 ? void 0 : course.stages) === null || _a === void 0 ? void 0 : _a[Math.floor((activeStep - 1) / 2)]) === null || _b === void 0 ? void 0 : _b.questions) === null || _c === void 0 ? void 0 : _c.length) || 0) - 1);
        }
        else {
            if (activeQuestion > 0) {
                setActiveQuestion(function (q) { return q - 1; });
            }
            else if (canPrev()) {
                setActiveStage(function (s) { return s - 1; });
                // setActiveQuestion(0)
            }
            // setQuestionTime(true)
        }
    };
    var nextDisabled = function () {
        var _a, _b, _c, _d, _e;
        if (isQuestionTime) {
            var activeAnswer = (_c = (_b = (_a = activeStage === null || activeStage === void 0 ? void 0 : activeStage.questions) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; })) === null || _c === void 0 ? void 0 : _c[activeQuestion];
            // console.log(activeStage, activeStage?.questions, activeQuestion, activeStage?.question?.[activeQuestion]?.answer)
            if (((_d = activeAnswer === null || activeAnswer === void 0 ? void 0 : activeAnswer.answer) === null || _d === void 0 ? void 0 : _d.answer) || !(((_e = activeAnswer === null || activeAnswer === void 0 ? void 0 : activeAnswer.answers) === null || _e === void 0 ? void 0 : _e.length) > 0)) {
                return false;
            }
            else {
                return true;
            }
        }
        return false;
    };
    return {
        next: next,
        prev: prev,
        canNext: canNext,
        canPrev: canPrev,
        nextDisabled: nextDisabled,
        isQuestionTime: isQuestionTime,
        activeStep: normStep,
        activeQuestion: activeQuestion,
        activeStage: activeStage,
        setActiveStage: setActiveStage
    };
};
