import { createTheme, ThemeProvider } from '@mui/material';
export { ThemeProvider };
export var globalTheme = createTheme({
    palette: {
        primary: {
            // light: '#fff8f2',
            main: '#8accf9'
        },
        secondary: {
            // light: '#a3b579',
            main: "#007062"
        },
        text: {
            primary: '#200049',
            secondary: '#200049'
        },
        background: {
            default: '#ECFBFF',
            // default: '#c7ebff',
            paper: '#fff' //'#eefaff'
        }
    },
    typography: {
        fontFamily: "\"Montserrat\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    },
    components: {
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: '#c7ebff',
                    fontSize: '1em',
                    padding: '12px',
                    backgroundColor: '#007062'
                }
            }
        }
    }
});
// export const HexHiveTheme = createTheme({
//     palette: {
//       ...globalTheme.palette
//     },
//     components: {
//       MuiPaper: {
//         styleOverrides: {
//           root: {
//             background: globalTheme.palette.primary.light,
//             borderRadius: '6px',
//             overflow: "hidden"
//           }
//         }
//       },
//       MuiTableHead: {
//         styleOverrides: {
//           root: {
//             backgroundColor: globalTheme.palette.secondary.main,
//             '& .MuiTableRow-root .MuiTableCell-root': {
//               color: 'white'
//             },
//             '& .MuiTableRow-root .MuiTableSortLabel-root': {
//               color: 'white'
//             },
//             '& .MuiTableRow-root .MuiTableSortLabel-root.Mui-active': {
//               color: 'white'
//             },
//             '& .MuiTableRow-root .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
//               color: 'white'
//             },
//             '& .MuiTableRow-root .MuiTableSortLabel-root .MuiTableSortLabel-iconDirectionDesc': {
//               color: 'white'
//             },
//             '& .MuiTableRow-root .MuiTableSortLabel-root .MuiTableSortLabel-iconDirectionAsc': {
//               color: 'white'
//             }
//           }
//         }
//       },
//       MuiTabs: {
//         styleOverrides: {
//           root: {
//             minHeight: '36px',
//           },
//           indicator: {
//             background: globalTheme.palette.navigation.main
//           }
//         }
//       },
//       MuiTab: {
//         styleOverrides: {
//           root: {
//             padding: '6px',
//             color: globalTheme.palette.navigation.main,
//             minHeight: '36px',
//             '&.Mui-selected': {
//               color: globalTheme.palette.navigation.main
//             }
//           }
//         }
//       },
//       MuiDialogContent: {
//         styleOverrides: {
//           root: {
//             padding: '6px'
//           }
//         }
//       },
//       MuiDialogActions: {
//         styleOverrides: {
//           root: {
//             padding: '6px',
//             paddingTop: '6px'
//           }
//         }
//       },
//       MuiDialogTitle: {
//         styleOverrides: {
//           root: {
//             background: globalTheme.palette.secondary.main,
//             color: '#fff',
//             padding: '6px',
//             fontSize: '16px',
//             marginBottom: '6px'
//           }
//         }
//       },
//       MuiMenu: {
//         styleOverrides: {
//             paper: {
//                 background: 'white'
//             }
//         }
//       }
//     }
//   });
