var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Paper, Typography } from '@mui/material';
export var CourseQuestions = function (props) {
    var _a, _b, _c, _d, _e, _f;
    console.log({ props: props });
    var question = (_c = (_b = (_a = props.questions) === null || _a === void 0 ? void 0 : _a.slice()) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.index - b.index; })) === null || _c === void 0 ? void 0 : _c[props.activeQuestion || 0];
    return (_jsxs(Box, __assign({ sx: { flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column' } }, { children: [_jsx(Typography, __assign({ sx: { marginBottom: '12px' } }, { children: question === null || question === void 0 ? void 0 : question.text })), _jsx(Box, __assign({ sx: { flex: 1, minHeight: 0, overflowY: 'auto' } }, { children: (_f = (_e = (_d = question === null || question === void 0 ? void 0 : question.answers) === null || _d === void 0 ? void 0 : _d.slice()) === null || _e === void 0 ? void 0 : _e.sort(function (a, b) { return a.index - b.index; })) === null || _f === void 0 ? void 0 : _f.map(function (answer) {
                    var _a;
                    return (_jsx(Paper, __assign({ onClick: function () {
                            var _a;
                            (_a = props.onAnswerChange) === null || _a === void 0 ? void 0 : _a.call(props, question === null || question === void 0 ? void 0 : question.id, answer.id);
                        }, sx: {
                            bgcolor: ((_a = question === null || question === void 0 ? void 0 : question.answer) === null || _a === void 0 ? void 0 : _a.answer) === answer.id ? 'primary.main' : 'background.default',
                            // background: question?.answer?.answer === answer.id ? '#c4f0fb' : undefined, // (question?.answer?.isCorrect ? 'rgba(0, 128, 0, 0.7)' : 'rgba(211, 47, 47, 0.7)'): undefined,
                            cursor: 'pointer',
                            marginBottom: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px',
                            // minWidth: '50%'
                        } }, { children: _jsx(Typography, __assign({ sx: {
                            // color: question?.answer?.answer === answer.id ? (question?.answer?.isCorrect ? 'background.default' : 'background.default'): undefined,
                            } }, { children: answer.text })) })));
                }) }))] })));
};
