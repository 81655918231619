var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Dialog } from '@mui/material';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Typography } from '@mui/material';
export var CardModal = function (props) {
    var elements = useElements();
    var stripe = useStripe();
    var _a = useState(), error = _a[0], setError = _a[1];
    useEffect(function () {
        setError(null);
    }, [props.open]);
    return (_jsxs(Dialog, __assign({ fullWidth: true, onClose: props.onClose, open: props.open }, { children: [_jsx(DialogTitle, { children: "Add Card" }), _jsxs(DialogContent, __assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '12px !important',
                } }, { children: [error ? (_jsx(Typography, __assign({ color: "error" }, { children: error }))) : null, _jsx(Box, __assign({ sx: { marginTop: '24px' } }, { children: _jsx(CardNumberElement, { options: {
                                style: {
                                    base: {
                                        fontSize: '18px'
                                    }
                                }
                            } }) })), _jsxs(Box, __assign({ sx: { display: 'flex', marginTop: '24px' } }, { children: [_jsx(Box, __assign({ sx: { flex: 0.7 } }, { children: _jsx(CardExpiryElement, { options: {
                                        style: {
                                            base: {
                                                fontSize: '18px'
                                            }
                                        }
                                    } }) })), _jsx(Box, __assign({ sx: { flex: 0.3 } }, { children: _jsx(CardCvcElement, { options: {
                                        style: {
                                            base: {
                                                fontSize: '18px'
                                            }
                                        }
                                    } }) }))] }))] })), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: props.onClose }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: function () {
                            if (!elements)
                                return;
                            var cardElement = elements.getElement('cardNumber');
                            if (!cardElement)
                                return;
                            stripe === null || stripe === void 0 ? void 0 : stripe.createSource(cardElement, {
                                type: 'card'
                            }).then(function (data) {
                                var _a, _b, _c, _d;
                                if ((_a = data.error) === null || _a === void 0 ? void 0 : _a.message) {
                                    return setError(data.error.message);
                                }
                                // data.source.t
                                if ((_b = data.source) === null || _b === void 0 ? void 0 : _b.id) {
                                    (_c = props.onSubmit) === null || _c === void 0 ? void 0 : _c.call(props, (_d = data.source) === null || _d === void 0 ? void 0 : _d.id);
                                }
                            });
                        }, color: "primary", variant: "contained" }, { children: "Save" }))] })] })));
};
